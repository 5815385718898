import React from "react";
import { usePlotContext } from "../../../../../contexts/PlotContext";
import { BaseSettings } from "../../../../../types/plot_settings/plot_settings_interfaces";
import EnterDate from "../setting_inputs/DateRangeInput";
import { Box, Stack } from "@mui/material";

const DateSettings: React.FC = () => {
  const { plot } = usePlotContext();

  const isValidSettings = (settings: any): settings is BaseSettings => {
    const baseSettingsKeys = Object.keys(settings) as Array<keyof BaseSettings>;
    return baseSettingsKeys.every((key) => key in settings);
  };

  const settings = plot.config.settings as BaseSettings;
  if (!isValidSettings(settings)) {
    throw new Error("Settings are invalid");
  }

  return (
    <Stack gap={1}>
      <EnterDate variant="text" />
      <Box display="flex" gap={1} rowGap={1} flexWrap="wrap">
        <EnterDate variant="button" label="3M" defaultDate="3M" />
        <EnterDate variant="button" label="1Y" defaultDate="1Y" />
        <EnterDate variant="button" label="5Y" defaultDate="5Y" />
        <EnterDate variant="button" label="10Y" defaultDate="10Y" />
        <EnterDate variant="button" label="2022" defaultDate="2022" />
        <EnterDate variant="button" label="2018" defaultDate="2018" />
        <EnterDate
          variant="button"
          label="2000-2003"
          defaultDate="2000::2003"
        />
        <EnterDate
          variant="button"
          label="2007-2009"
          defaultDate="2007::2009"
        />
      </Box>
    </Stack>
  );
};

DateSettings.displayName = "Date Range";
export default DateSettings;
