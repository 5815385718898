import { BaseTheme, ThemeName } from "./plot_theme_interfaces";

export const returnStackedTheme: BaseTheme = {
  theme_name: ThemeName.RETURN_STACKED,
  colors: {
    palette: [
      "#323a46",
      "#14cfa6",
      "#3a6a9c",
      "#7da5ce",
      "#ffe885",
      "#ebe96a",
      "#0a0a0b",
      "#0c7c64",
      "#23405e",
      "#366390",
      "#3bb823",
      "#B4B218",
      "#3F3B47",
      "#31EBC2",
      "#5287BF",
      "#97B7D8",
      "#AAED9D",
      "#EFED88",
      "#0E0D0F",
      "#10A685",
    ],
    negative: "#323a46",
    positive: "#14cfa6",
  },
  font_family: "DM Sans",
};

export const resolveTheme: BaseTheme = {
  theme_name: ThemeName.RESOLVE,
  colors: {
    palette: [
      "#00488d",
      "#fbba00",
      "#000000",
      "#89d2ff",
      "#6f4596",
      "#d3d800",
      "#81aec6",
      "#f29400",
      "#b3d852",
      "#00abff",
      "#7e768c",
      "#e6c245",
      "#63cc77",
      "#293645",
      "#88949e",
    ],
    negative: "#87d1ff",
    positive: "#00488d",
  },
  font_family: "Helvetica Neue",
};

export const THEMES: Record<ThemeName, BaseTheme> = {
  [ThemeName.RETURN_STACKED]: returnStackedTheme,
  [ThemeName.RESOLVE]: resolveTheme,
};
