import { PlotType } from "../plot_requests/plot_request_interfaces";
import { ThemeName } from "../plot_theme/plot_theme_interfaces";
import {
  BarPlotSettings,
  CorrelPlotSettings,
  LinePlotSettings,
} from "./plot_settings_interfaces";

export type PlotTypeToPartialSettingsMap = {
  [PlotType.LinePlot]: Partial<LinePlotSettings>;
  [PlotType.BarPlot]: Partial<BarPlotSettings>;
  [PlotType.CorrelPlot]: Partial<CorrelPlotSettings>;
};

export const themeSettings: Partial<
  Record<ThemeName, Partial<PlotTypeToPartialSettingsMap>>
> = {
  [ThemeName.RETURN_STACKED]: {
    [PlotType.LinePlot]: {
      canvas_color: "#f2f2f2",
    },
    [PlotType.BarPlot]: {
      canvas_color: "#f2f2f2",
    },
  },
};
