import { RAMTextField } from "../../../../../../../resources/CustomInputs";
import { InputProps } from "./InputProps";

export const TextInput: React.FC<InputProps<string>> = ({
  label,
  value,
  onChange,
  sx,
  disabled = false,
}) => (
  <RAMTextField
    fullWidth
    label={label}
    defaultValue={value}
    onBlur={(e) => onChange(e.target.value)}
    size="small"
    sx={sx}
    disabled={disabled}
  />
);
