import React, { useCallback } from "react";
import { InputProps } from "./InputProps";

export const ColorInput: React.FC<InputProps<string>> = ({
  value,
  onChange,
}) => {
  const [currentColor, setCurrentColor] = React.useState(value);
  const [open, setOpen] = React.useState(false);

  // Debounce function
  const debounce = (func: Function, delay: number) => {
    let timer: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleColorChange = useCallback(
    debounce((color: string) => {
      setCurrentColor(color);
      onChange(color);
      handleClose();
    }, 300),
    [],
  );

  return (
    <>
      <input
        type="color"
        value={currentColor || "#000000"}
        onChange={(e) => handleColorChange(e.target.value)}
        style={{
          width: "100%",
          height: "40px",
          padding: "0",
          backgroundColor: "transparent",
          border: "none",
        }}
      />
    </>
  );
};

export default ColorInput;
