export interface DataConfig {
  start_date: string;
  end_date: string;
  portfolios: Portfolio[];
  transform?: Transform;
  periodicity?: Periodicity;
}

export enum Periodicity {
  DAILY = "Daily",
  MONTHLY = "Monthly",
}

export interface File {
  path: string;
}

export interface Portfolio {
  files: File[];
  name: string;
  weights: number[];
}

export interface FilesByBin {
  [bin: string]: string[];
}

export interface Metadata {
  name: string;
  isValid: boolean;
  dateRange?: string;
  bin: string;
  last_updated: string;
  validation_errors: string[];
}

export type FilesMetadata = { [path: string]: Metadata };

interface TransformKwargs {
  [key: string]: number | string | Portfolio;
}

export type TransformMethod =
  | "investment_performance"
  | "rolling_volatility"
  | "rolling_returns"
  | "rolling_correlation"
  | "rolling_drawdowns";

export interface Transform {
  transform: TransformMethod;
  kwargs: TransformKwargs;
}
