import React from "react";
import { Select, InputLabel, MenuItem } from "@mui/material";
import { InputProps } from "./InputProps";
import { RAMFormControl } from "../../../../../../../resources/CustomInputs";

interface SelectInputProps<T> extends InputProps<T> {
  options: Record<string, T>;
}

export const SelectInput: React.FC<SelectInputProps<any>> = ({
  label,
  value,
  onChange,
  options,
  sx,
  disabled = false,
}) => (
  <RAMFormControl fullWidth size="small">
    <InputLabel>{label}</InputLabel>
    <Select
      MenuProps={{
        sx: {
          "& .MuiButtonBase-root.MuiMenuItem-root": {
            fontSize: "12px",
          },
          "& .Mui-selected": {
            fontSize: "12px",
          },
        },
      }}
      value={value}
      onChange={(e) => onChange(e.target.value as string)}
      label={label}
      sx={sx}
      disabled={disabled}
    >
      {Object.entries(options).map(([key, value]) => (
        <MenuItem key={key} value={value}>
          {key}
        </MenuItem>
      ))}
    </Select>
  </RAMFormControl>
);
