import React from "react";
import { InputProps } from "./InputProps";
import { Justify } from "../../../../../types/plot_settings/plot_settings_interfaces";
import { Box, Stack } from "@mui/material";
import { TextInput } from "./TextInput";
import { NumberInput } from "./NumberInput";
import { JustifyInput } from "./JustifyInput";

interface LabelInputProps<T> extends InputProps<T> {
  fontSize: number;
  justify?: Justify;
  onFontSizeChange: (value: number) => void;
  onJustifyChange?: (value: Justify) => void;
}

export const LabelInput: React.FC<LabelInputProps<string>> = ({
  value,
  fontSize,
  justify,
  onChange,
  label,
  onFontSizeChange,
  onJustifyChange,
  sx,
}) => {
  return (
    <Stack gap={1} sx={sx}>
      <TextInput
        value={value}
        label={label}
        onChange={(text) => onChange(text)}
      />
      <Box display="flex" alignItems="flex-end" gap={1}>
        <NumberInput
          label="Font Size"
          value={fontSize}
          onChange={(fontSize) => onFontSizeChange(Number(fontSize))}
        />

        {justify && (
          <JustifyInput
            value={justify}
            onChange={(value) => onJustifyChange && onJustifyChange(value)}
          />
        )}
      </Box>
    </Stack>
  );
};

export default LabelInput;
