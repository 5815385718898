import React from "react";
import {
  RAMTextField,
  RAMFormControl,
} from "../../../../../../resources/CustomInputs";
import {
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  SxProps,
  Theme,
  Tooltip,
  useTheme,
  Stack,
} from "@mui/material";
import {
  Justify,
  PlotSettings,
} from "../../../../types/plot_settings/plot_settings_interfaces";
import {
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
} from "@mui/icons-material";

interface FieldProps<T> {
  label: string;
  value: T;
  args?: Record<string, any>;
  onChange: (value: T) => void;
}

export const StringField: React.FC<FieldProps<string>> = ({
  label,
  value,
  onChange,
}) => (
  <RAMTextField
    fullWidth
    label={label}
    defaultValue={value}
    onBlur={(e) => onChange(e.target.value)}
    size="small"
  />
);

export const NumberField: React.FC<FieldProps<number>> = ({
  label,
  value,
  onChange,
}) => (
  <RAMTextField
    fullWidth
    label={label}
    type="number"
    defaultValue={value}
    onBlur={(e) => onChange(Number(e.target.value))}
    size="small"
  />
);

export const BooleanField: React.FC<
  FieldProps<boolean> & { args: { iconType: React.ElementType } }
> = ({ label, value, onChange, args }) => {
  const IconComponent = args.iconType;
  const theme = useTheme();
  return (
    <Tooltip title={label}>
      <ToggleButton
        value="check"
        selected={value}
        onChange={() => onChange(!value)}
        size="small"
        sx={{
          width: "fit-content",
          "&.Mui-selected": {
            fontWeight: 700,
          },
        }}
      >
        <IconComponent />
      </ToggleButton>
    </Tooltip>
  );
};

export const SelectField: React.FC<FieldProps<string>> = ({
  label,
  value,
  onChange,
  args,
}) => (
  <RAMFormControl fullWidth size="small">
    <InputLabel>{label}</InputLabel>
    <Select
      value={value}
      onChange={(e) => onChange(e.target.value as string)}
      label={label}
    >
      {args?.options &&
        Object.entries(args.options as Record<string, string>).map(
          ([optionValue, optionLabel]) => (
            <MenuItem key={optionValue} value={optionValue}>
              {optionLabel}
            </MenuItem>
          ),
        )}
    </Select>
  </RAMFormControl>
);

export const ArrayField: React.FC<FieldProps<[number, number]>> = ({
  label,
  value,
  onChange,
}) => (
  <Stack gap={1}>
    <Typography variant="caption">{label}</Typography>
    <Box display="flex" gap={1}>
      <RAMTextField
        label="Width"
        type="number"
        value={value[0]}
        onChange={(e) => onChange([Number(e.target.value), value[1]])}
        size="small"
      />
      <RAMTextField
        label="Height"
        type="number"
        value={value[1]}
        onChange={(e) => onChange([value[0], Number(e.target.value)])}
        size="small"
      />
    </Box>
  </Stack>
);

export const JustifyField: React.FC<
  FieldProps<Justify> & { sx?: SxProps<Theme> }
> = ({ label, value, onChange, sx }) => (
  <Box sx={sx}>
    {label !== "" && <Typography variant="caption">Justification</Typography>}
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(_, newValue) => newValue && onChange(newValue)}
      aria-label="text alignment"
      size="small"
      fullWidth
    >
      <ToggleButton value="left" aria-label="left aligned">
        <FormatAlignLeft />
      </ToggleButton>
      <ToggleButton value="center" aria-label="centered">
        <FormatAlignCenter />
      </ToggleButton>
      <ToggleButton value="right" aria-label="right aligned">
        <FormatAlignRight />
      </ToggleButton>
    </ToggleButtonGroup>
  </Box>
);

interface TitleSettingsFieldProps {
  settings: PlotSettings;
  label: string;
  args: Record<string, any>;
  fields: { [name: string]: keyof PlotSettings };
  onChange: <K extends keyof PlotSettings>(
    key: K,
    value: PlotSettings[K],
  ) => void;
}
export const LabelSettingsField: React.FC<TitleSettingsFieldProps> = ({
  settings,
  onChange,
  label,
  args,
  fields,
}) => {
  return (
    <Box>
      <Typography variant="caption">{label}</Typography>
      <RAMTextField
        fullWidth
        defaultValue={settings[fields.text] || ""}
        onBlur={(e) => onChange(fields.text, e.target.value)}
        label={args.titleLabel}
        margin="dense"
        sx={{ mb: 1 }}
      />
      <Box display="flex" alignItems="flex-end" gap={1}>
        <RAMTextField
          type="number"
          label="Font Size"
          defaultValue={settings[fields.fontSize]}
          onBlur={(e) => onChange(fields.fontSize, Number(e.target.value))}
          sx={{
            maxWidth: "75px",
            "& .MuiInputBase-input": {
              padding: "6px",
              fontSize: "8pt",
            },
          }}
        />
        <JustifyField
          label=""
          value={settings[fields.justify] as Justify}
          onChange={(value) => onChange(fields.justify, value)}
        />
      </Box>
    </Box>
  );
};
