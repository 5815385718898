import React, { useState, useEffect } from "react";
import { AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "../../../../../../resources/Accordion";
import { usePlotContext } from "../../../../contexts/PlotContext";
import TitleSettings from "./setting_groups/TitleSettings";
import MiscLineSettings from "./setting_groups/MiscLineSettings";
import AxesSettings from "./setting_groups/AxesSettings";
import AnnotationSettings from "./setting_groups/AnnotationSettings";
import MiscCorrelSettings from "./setting_groups/MiscCorrelSettings";
import LayoutSettings from "./setting_groups/LayoutSettings";
import HorizontalLineSettings from "./setting_groups/HorizontalLineSettings";
import DateSettings from "./setting_groups/DateSettings";

type SettingsGroups = Record<string, React.ComponentType[]>;

// GROUPS: Map of which setting groups to render for each plot type
const GROUPS: SettingsGroups = {
  line_plot: [
    DateSettings,
    TitleSettings,
    LayoutSettings,
    AxesSettings,
    MiscLineSettings,
    HorizontalLineSettings,
  ],
  correl_plot: [
    TitleSettings,
    DateSettings,
    LayoutSettings,
    AxesSettings,
    AnnotationSettings,
    MiscCorrelSettings,
  ],
  bar_plot: [TitleSettings, DateSettings, LayoutSettings, AxesSettings],
};

const SettingsPanel: React.FC = () => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const { plot } = usePlotContext();
  const [currentSettings, setCurrentSettings] = useState<React.ComponentType[]>(
    [],
  );

  // Update currentSettings whenever plot.plot_type changes
  useEffect(() => {
    if (plot.config.plot_type && GROUPS[plot.config.plot_type]) {
      setCurrentSettings(GROUPS[plot.config.plot_type]);
    }
  }, [plot.config.plot_type]);

  // handles the opening and closing of accordians
  const handleAcordianChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };


  return (
    <div>
      {currentSettings.map((SettingComponent, index) => (
        <Accordion
          key={index}
          expanded={expanded === SettingComponent.name}
          onChange={handleAcordianChange(SettingComponent.name)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${SettingComponent.displayName}-content`}
            id={`${SettingComponent.displayName}-header`}
          >
            <Typography fontSize={11}>
              {SettingComponent.displayName}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SettingComponent />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default SettingsPanel;
