import React from "react";
import { LinePlotSettings } from "../../../../../types/plot_settings/plot_settings_interfaces";
import HeightIcon from "@mui/icons-material/Height";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { usePlotContext } from "../../../../../contexts/PlotContext";
import { Stack, Box } from "@mui/material";
import { ToggleInput } from "../setting_inputs/ToggleInput";

const MiscLineSettings: React.FC = () => {
  const { plot } = usePlotContext();

  const isValidSettings = (settings: any): settings is LinePlotSettings => {
    const baseSettingsKeys = Object.keys(settings) as Array<
      keyof LinePlotSettings
    >;
    return baseSettingsKeys.every((key) => key in settings);
  };

  const settings = plot.config.settings as LinePlotSettings;
  if (!isValidSettings(settings)) {
    throw new Error("Settings are invalid");
  }

  return (
    <Stack gap={2}>
      <Box display="flex" gap={2}>
        <ToggleInput
          IconComponent={HeightIcon}
          label="Scale Y-Axis (Log2)"
          value={settings.scale_y_log2}
          onChange={(value) =>
            plot.update({
              ...plot,
              settings: {
                ...settings,
                scale_y_log2: value,
              },
            })
          }
        />
        <ToggleInput
          IconComponent={CalendarMonthIcon}
          label="Show Date Range"
          value={settings.show_date_range}
          onChange={(value) =>
            plot.update({
              ...plot,
              settings: {
                ...settings,
                show_date_range: value,
              },
            })
          }
        />
      </Box>
    </Stack>
  );
};

MiscLineSettings.displayName = "Misc";
export default MiscLineSettings;
