import React from "react";
import { usePlotContext } from "../../../../../contexts/PlotContext";
import { BaseSettings } from "../../../../../types/plot_settings/plot_settings_interfaces";
import { Box, Stack } from "@mui/material";
import { NumberInput } from "../setting_inputs/NumberInput";
import { ToggleInput } from "../setting_inputs/ToggleInput";
import GridOnIcon from "@mui/icons-material/GridOn";
import BorderOuterIcon from "@mui/icons-material/BorderOuter";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import { SelectInput } from "../setting_inputs/SelectInput";
import { LEGEND_POSITION_OPTIONS } from "../../../../../types/plot_settings/plot_settings_info";

const LayoutSettings: React.FC = () => {
  const { plot } = usePlotContext();

  const isValidSettings = (settings: any): settings is BaseSettings => {
    const baseSettingsKeys = Object.keys(settings) as Array<keyof BaseSettings>;
    return baseSettingsKeys.every((key) => key in settings);
  };

  const settings: BaseSettings = plot.config.settings;
  if (!isValidSettings(settings)) {
    throw new Error("Settings are invalid");
  }

  return (
    <Stack gap={2}>
      <Box display="flex" gap={1}>
        <NumberInput
          label="Height"
          value={settings.figsize[0]}
          onChange={(value) =>
            plot.setSettings({
              figsize: [value, settings.figsize[1]],
            })
          }
        />
        <NumberInput
          label="Width"
          value={settings.figsize[1]}
          onChange={(value) =>
            plot.setSettings({
              figsize: [settings.figsize[0], value],
            })
          }
        />
      </Box>
      <Box display="flex" gap={2}>
        <ToggleInput
          IconComponent={GridOnIcon}
          label="Panel Grid"
          value={settings.show_panel_grid}
          onChange={(value) =>
            plot.setSettings({
              ...settings,
              show_panel_grid: value,
            })
          }
        />
        <ToggleInput
          IconComponent={BorderOuterIcon}
          label="Panel Border"
          value={settings.panel_border}
          onChange={(value) =>
            plot.setSettings({
              panel_border: value,
            })
          }
        />
        <ToggleInput
          IconComponent={LegendToggleIcon}
          label="Show Legend"
          value={settings.show_legend}
          onChange={(value) =>
            plot.setSettings({
              show_legend: value,
            })
          }
        />
        <SelectInput
          label="Legend Position"
          value={settings.legend_position}
          disabled={!settings.show_legend}
          onChange={(value) =>
            plot.setSettings({
              legend_position: value,
            })
          }
          options={LEGEND_POSITION_OPTIONS}
        />

        <NumberInput
          label="Legend Font Size"
          value={settings.legend_fontsize || 12}
          disabled={!settings.show_legend}
          onChange={(value) => plot.setSettings({ legend_fontsize: value })}
        />
      </Box>
    </Stack>
  );
};

LayoutSettings.displayName = "Layout";
export default LayoutSettings;
