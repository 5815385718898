import {
  StringField,
  NumberField,
  BooleanField,
  SelectField,
  ArrayField,
} from "../../components/panel_drawer/panels/SettingsPanel/SettingsFields";
import {
  AxisTickAngle,
  LegendPosition,
  AnnotationColor,
  PlotSettings,
} from "./plot_settings_interfaces";
import { CheckCircleOutline } from "@mui/icons-material";
import LabelIcon from "@mui/icons-material/Label";
import BorderOuterIcon from "@mui/icons-material/BorderOuter";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import HeightIcon from "@mui/icons-material/Height";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AppsIcon from "@mui/icons-material/Apps";
import CropSquareIcon from "@mui/icons-material/CropSquare";

export const AXIS_TICK_ANGLE_OPTIONS: Record<string, AxisTickAngle> = {
  "0°": "0",
  "45°": "45",
  "90°": "90",
};

export const LEGEND_POSITION_OPTIONS: Record<string, LegendPosition> = {
  Top: "top",
  Bottom: "bottom",
  Left: "left",
  Right: "right",
};
export const HORIZONTAL_LINE_OPTIONS: Record<string, string> = {
  Solid: "solid",
  Dashed: "dashed",
  Dotted: "dotted",
  "Dot-Dash": "dotdash",
  "Long-Dash": "longdash",
  "Two-Dash": "twodash",
};

export const DATE_TIME_FORMAT_OPTIONS: Record<string, any> = {
  Auto: null,
  "Month/Day/Year": "%m/%d/%Y",
  "Month-Year": "%m-%Y",
  "Month, Year": "%b %e, %Y",
};

export const AXIS_TICK_FORMATS: Record<string, string | null> = {
  None: null,
  "100": ".0f",
  "100.00": ".2f",
  $100: "$,.0f",
  "$100.00": "$,.2f",
};

export const ANNOTATION_COLOR_OPTIONS: Record<string, AnnotationColor> = {
  White: "white",
  Black: "black",
  Auto: "auto",
};

export type SettingGroup =
  | "General"
  | "Axes"
  | "Legend"
  | "Line Plot"
  | "Waterfall Plot"
  | "Correlation Plot";

export type CompositeField = {
  type: "composite";
  name: string;
  args: Record<string, any>;
  fields: { [name: string]: keyof PlotSettings };
  component: React.ComponentType<any>;
};

export type SettingInfo =
  | {
      name: string;
      component: React.ComponentType<any>;
      args?: Record<string, any>;
      group: SettingGroup;
    }
  | CompositeField;

export interface FieldConfig {
  type: string;
  name: string;
  component: React.ComponentType<any>;
  args?: Record<string, any>;
  group: string;
  fields?: { [name: string]: keyof PlotSettings };
}

export const PLOT_SETTINGS_INFO: Record<
  keyof PlotSettings | string,
  FieldConfig
> = {
  // Base Settings

  x_label: {
    name: "X-Axis Label",
    type: "string",
    component: StringField,
    group: "Axes",
  },
  x_label_fontsize: {
    name: "X-Axis Label Font Size",
    type: "string",
    component: NumberField,
    group: "Axes",
  },
  x_axis_tick_angle: {
    name: "X-Axis Tick Angle",
    type: "select",
    component: SelectField,
    args: { options: AXIS_TICK_ANGLE_OPTIONS },
    group: "Axes",
  },
  x_axis_tick_format: {
    name: "X-Axis Tick Format",
    type: "select",
    component: StringField,
    group: "Axes",
  },
  y_label: {
    name: "Y-Axis Label",
    type: "string",
    component: StringField,
    group: "Axes",
  },
  y_label_fontsize: {
    name: "Y-Axis Label Font Size",
    type: "string",
    component: NumberField,
    group: "Axes",
  },
  y_axis_tick_angle: {
    name: "Y-Axis Tick Angle",
    type: "select",
    component: SelectField,
    args: { options: AXIS_TICK_ANGLE_OPTIONS },
    group: "Axes",
  },
  x_axis_tick_fontsize: {
    name: "X-Axis Tick Font Size",
    type: "string",
    component: NumberField,
    group: "Axes",
  },
  y_axis_tick_fontsize: {
    name: "Y-Axis Tick Font Size",
    type: "string",
    component: NumberField,
    group: "Axes",
  },
  show_annotations: {
    name: "Bar Annotations",
    type: "boolean",
    component: BooleanField,
    group: "General",
    args: { iconType: LabelIcon },
  },
  axis_thickness: {
    name: "Axis Thickness",
    type: "string",
    component: NumberField,
    group: "Axes",
  },
  axis_color: {
    name: "Axis Color",
    type: "string",
    component: StringField,
    group: "Axes",
  },
  figsize: {
    name: "Figure Size",
    type: "figsize",
    component: ArrayField,
    group: "General",
  },
  panel_border: {
    name: "Show Panel Border",
    type: "boolean",
    component: BooleanField,
    group: "General",
    args: { iconType: BorderOuterIcon },
  },
  show_legend: {
    name: "Show Legend",
    type: "boolean",
    component: BooleanField,
    group: "Legend",
    args: { iconType: LegendToggleIcon },
  },
  legend_position: {
    name: "Legend Position",
    type: "select",
    component: SelectField,
    args: { options: LEGEND_POSITION_OPTIONS },
    group: "Legend",
  },
  legend_title: {
    name: "Legend Title",
    type: "select",
    component: StringField,
    group: "Legend",
  },
  legend_fontsize: {
    name: "Legend Font Size",
    type: "string",
    component: NumberField,
    group: "Legend",
  },

  // Line Plot Settings
  scale_y_log2: {
    name: "Scale Y-Axis (Log2)",
    component: BooleanField,
    type: "boolean",
    group: "Line Plot",
    args: { iconType: HeightIcon },
  },
  show_date_range: {
    name: "Show Date Range",
    type: "boolean",
    component: BooleanField,
    group: "Line Plot",
    args: { iconType: CalendarMonthIcon },
  },
  place_horizontal_line_at: {
    name: "Horizontal Line Position",
    type: "string",
    component: NumberField,
    group: "Line Plot",
  },
  horizontal_line_type: {
    name: "Horizontal Line Type",
    type: "string",
    component: StringField,
    group: "Line Plot",
  },
  horizontal_line_color: {
    name: "Horizontal Line Color",
    type: "string",
    component: StringField,
    group: "Line Plot",
  },
  horizontal_line_size: {
    name: "Horizontal Line Size",
    type: "string",
    component: NumberField,
    group: "Line Plot",
  },

  // Waterfall Plot Settings
  sorted_value: {
    name: "Sort by Value",
    type: "boolean",
    component: BooleanField,
    group: "Waterfall Plot",
    args: { iconType: CheckCircleOutline },
  },
  threshold: {
    name: "Threshold",
    component: NumberField,
    type: "string",
    group: "Waterfall Plot",
  },
  max_values: {
    name: "Maximum Values",
    type: "string",
    component: NumberField,
    group: "Waterfall Plot",
  },
  calc_total: {
    name: "Calculate Total",
    component: BooleanField,
    type: "boolean",
    group: "Waterfall Plot",
    args: { iconType: CheckCircleOutline },
  },
  total_title: {
    name: "Total Title",
    type: "string",
    component: StringField,
    group: "Waterfall Plot",
  },

  // Correlation Plot Settings
  dpi: {
    name: "DPI",
    type: "string",
    component: NumberField,
    group: "Correlation Plot",
  },
  show_annotation: {
    name: "Show Annotations",
    type: "boolean",
    component: BooleanField,
    group: "Correlation Plot",
    args: { iconType: LabelIcon },
  },
  annotation_fmt: {
    name: "Annotation Format",
    type: "string",
    component: StringField,
    group: "Correlation Plot",
  },
  annotation_fontsize: {
    name: "Annotation Font Size",
    type: "string",
    component: NumberField,
    group: "Correlation Plot",
  },
  annotation_color: {
    name: "Annotation Color",
    type: "select",
    component: SelectField,
    group: "Correlation Plot",
    args: { options: ANNOTATION_COLOR_OPTIONS },
  },
  annotation_decimals: {
    name: "Annotation Decimals",
    type: "string",
    component: NumberField,
    group: "Correlation Plot",
  },
  square: {
    name: "Square Plot",
    component: BooleanField,
    type: "boolean",
    group: "Correlation Plot",
    args: { iconType: CropSquareIcon },
  },
  linewidths: {
    name: "Line Widths",
    type: "string",
    component: NumberField,
    group: "Correlation Plot",
  },
  mask_upper: {
    name: "Mask Upper Triangle",
    type: "boolean",
    component: BooleanField,
    group: "Correlation Plot",
    args: { iconType: AppsIcon },
  },
};
