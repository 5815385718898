import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";

const CustomAccordion = styled(MuiAccordion)(({ theme }) => ({
  "&.MuiAccordion-root": {
    boxShadow: "none",
  },
  "& .MuiAccordionSummary-root": {
    backgroundColor: theme.palette.background.paper,
    minHeight: "unset",
    height: "40px",
    padding: "0 10px",
    borderTop: "none",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "unset",
    maxHeight: "40px",
    height: "40px",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  "& .MuiAccordionDetails-root": {
    paddingTop: "15px",
    backgroundColor: theme.palette.background.paper,
  },
  "& .MuiAccordionDetails-root.Mui-expanded": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.primary.main,
  },
  "& svg": {
    fontSize: "1.1rem",
  },
  "&.MuiPaper-root.MuiAccordion-root.Mui-expanded": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));
export const Accordion = CustomAccordion;
export default Accordion;
