export enum ThemeName {
  RESOLVE = "ReSolve",
  RETURN_STACKED = "Return Stacked",
}

export interface BaseTheme {
  theme_name: ThemeName;
  colors: ThemeColors;
  font_family: string;
}
interface ThemeColors {
  palette: string[];
  positive: string;
  negative: string;
}
