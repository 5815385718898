import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import exportImage from "./export.png";
import dateRangeImage from "./date_range.png";
import settingsPanelImage from "./settings_panel.png";
import authImage from "./auth.png";
import { useTheme } from "@mui/material/styles";

interface LargePopupProps {
  open: boolean;
  onClose: () => void;
}

const LargePopup: React.FC<LargePopupProps> = ({ open, onClose }) => {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = React.useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  const features = [
    {
      title: "Export Workflow",
      description:
        "Enhance your reporting efficiency with our updated export workflow, featuring preset dimensions and a user-friendly interface designed to simplify your data export tasks.",
      image: exportImage,
    },
    {
      title: "Date Range Menu",
      description:
        "Quickly access key time periods with our improved date range menu, offering shortcuts to frequently used intervals like 1 year, 5 years, and 3 months, tailored for your analytical needs.",
      image: dateRangeImage,
    },
    {
      title: "Settings Panel",
      description:
        "Navigate the newly organized settings panel with ease, designed to streamline your configuration process and improve overall usability.",
      image: settingsPanelImage,
    },
    {
      title: "Authentication Prompt",
      description:
        "Benefit from enhanced security protocols with our immediate login prompt for unauthorized access, ensuring your work environment remains secure.",
      image: authImage,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          backgroundImage: "unset",
          backgroundColor: "#1a181b",
        },
      }}
    >
      <DialogTitle>v1.1 Release Notes</DialogTitle>
      <DialogContent>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {features.map((feature, index) => (
            <Tab key={index} label={feature.title} />
          ))}
        </Tabs>
        {features.map((feature, index) => (
          <div
            role="tabpanel"
            hidden={currentTab !== index}
            key={index}
            style={{ padding: "16px", textAlign: "center", height: "60vh" }}
          >
            {currentTab === index && (
              <>
                <Box
                  height="98%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    src={feature.image}
                    alt={feature.title}
                    style={{
                      maxHeight: "60vh",
                      width: "fit-content",
                      height: "auto",
                      marginBottom: "16px",
                    }}
                  />
                </Box>
                <Typography variant="body2" color="#9f9f9f">
                  {feature.description}
                </Typography>
              </>
            )}
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LargePopup;
