import {
  PlotSettings,
  LinePlotSettings,
  CorrelPlotSettings,
} from "../plot_settings/plot_settings_interfaces";
import { BaseTheme } from "../plot_theme/plot_theme_interfaces";
import { DataConfig } from "../data_config/data_config_interfaces";
import { PlotStyle, LinePlotStyle } from "../plot_style/plot_style_interfaces";

export enum PlotType {
  LinePlot = "line_plot",
  BarPlot = "bar_plot",
  CorrelPlot = "correl_plot",
}

interface BasePlotRequest {
  plot_type: PlotType;
  settings: PlotSettings;
  theme: BaseTheme;
  data_config: DataConfig;
  style?: PlotStyle;
}

// Line Plot Request
export interface LinePlotRequest extends BasePlotRequest {
  plot_type: PlotType.LinePlot;
  settings: LinePlotSettings;
  style?: LinePlotStyle;
}

// Bar Plot Request
export type Periodicity = "Days" | "Weeks" | "Months" | "Quarters" | "Years";
export interface BarPlotRequest extends BasePlotRequest {
  plot_type: PlotType.BarPlot;
  periodicity: Periodicity;
}

// Correlation Plot Request
export interface CorrelPlotRequest extends BasePlotRequest {
  plot_type: PlotType.CorrelPlot;
  settings: CorrelPlotSettings;
}

export type PlotRequest = LinePlotRequest | BarPlotRequest | CorrelPlotRequest;
