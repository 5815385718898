import React from "react";
import { usePlotContext } from "../../../../../contexts/PlotContext";
import { NumberInput } from "../setting_inputs/NumberInput";
import { TextInput } from "../setting_inputs/TextInput";
import { SelectInput } from "../setting_inputs/SelectInput";
import { ToggleInput } from "../setting_inputs/ToggleInput";
import { ANNOTATION_COLOR_OPTIONS } from "../../../../../types/plot_settings/plot_settings_info";
import { Box, Stack, Typography } from "@mui/material";
import { CorrelPlotSettings } from "../../../../../types/plot_settings/plot_settings_interfaces";

const AnnotationSettings: React.FC = () => {
  const { plot } = usePlotContext();

  const isValidSettings = (settings: any): settings is CorrelPlotSettings => {
    const baseSettingsKeys = Object.keys(settings) as Array<
      keyof CorrelPlotSettings
    >;
    return baseSettingsKeys.every((key) => key in settings);
  };

  const settings = plot.config.settings as CorrelPlotSettings;
  if (!isValidSettings(settings)) {
    throw new Error("Settings are invalid");
  }

  return (
    <Stack gap={2}>
      <Box
        display="flex"
        gap={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="caption" sx={{ fontSize: "0.7rem" }}>
          Show Annotations
        </Typography>
        <ToggleInput
          label="Show Annotations"
          value={settings.show_annotation}
          onChange={(value) =>
            plot.update({
              ...plot,
              settings: {
                ...settings,
                show_annotation: value,
              },
            })
          }
        />
      </Box>
      <Box display="flex" gap={2}>
        <TextInput
          disabled={!settings.show_annotation}
          label="Format"
          value={settings.annotation_fmt}
          onChange={(value) =>
            plot.update({
              ...plot,
              settings: {
                ...settings,
                annotation_fmt: value,
              },
            })
          }
        />
        <NumberInput
          disabled={!settings.show_annotation}
          label="Font Size"
          value={settings.annotation_fontsize}
          onChange={(value) =>
            plot.update({
              ...plot,
              settings: {
                ...settings,
                annotation_fontsize: value,
              },
            })
          }
        />
        <NumberInput
          disabled={!settings.show_annotation}
          label="Decimals"
          value={settings.annotation_decimals}
          onChange={(value) =>
            plot.update({
              ...plot,
              settings: {
                ...settings,
                annotation_decimals: value,
              },
            })
          }
        />
        <SelectInput
          disabled={!settings.show_annotation}
          label="Color"
          value={settings.annotation_color}
          onChange={(value) =>
            plot.update({
              ...plot,
              settings: {
                ...settings,
                annotation_color: value,
              },
            })
          }
          options={ANNOTATION_COLOR_OPTIONS}
        />
      </Box>
    </Stack>
  );
};

AnnotationSettings.displayName = "Annotations";
export default AnnotationSettings;
