import React from "react";
import { ToggleButton, Tooltip, Checkbox } from "@mui/material";
import { InputProps } from "./InputProps";

interface ToggleInputProps<T> extends InputProps<T> {
  IconComponent?: React.ElementType;
}

export const ToggleInput: React.FC<ToggleInputProps<boolean>> = ({
  label,
  value,
  onChange,
  IconComponent = null,
}) => {
  if (!IconComponent) {
    return (
      <Tooltip title={label}>
        <Checkbox
          checked={value || false}
          onChange={() => onChange(!value)}
          size="small"
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip title={label}>
      <ToggleButton
        value="check"
        selected={value || false}
        onChange={() => onChange(!value)}
        size="small"
        sx={{
          width: "fit-content",
          "&.Mui-selected": {
            fontWeight: 700,
          },
        }}
      >
        <IconComponent />
      </ToggleButton>
    </Tooltip>
  );
};
