import React from "react";
import {
  Box,
  Typography,
  Divider,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import {
  RAMTextField,
  RAMFormControl,
} from "../../../../../../resources/CustomInputs";
import { PlotType } from "../../../../types/plot_requests/plot_request_interfaces";
import { PlotStyle } from "../../../../types/plot_style/plot_style_interfaces";
import { PLOT_STYLE_INFO } from "../../../../types/plot_style/plot_style_info";
import {
  TransformMethod,
  Transform,
  Portfolio,
  Periodicity,
} from "../../../../types/data_config/data_config_interfaces";
import { LinePlotSettings } from "../../../../types/plot_settings/plot_settings_interfaces";
import PortfolioItem from "../DataConfigPanel/PortfolioItem";
import { usePlotContext } from "../../../../contexts/PlotContext";

interface TransformPanelProps {
  style: PlotStyle;
  plotTransform: Transform;
  onChange: (
    newTransform: Transform,
    styleName: PlotStyle | undefined,
    settings: Partial<LinePlotSettings>,
  ) => void;
}

const TransformPanel: React.FC<TransformPanelProps> = ({
  style,
  onChange,
  plotTransform,
}) => {
  const { plot } = usePlotContext();

  const handleTransformChange = (newTransformMethod: TransformMethod) => {
    const newStyleConfig =
      PLOT_STYLE_INFO[plot.config.plot_type][newTransformMethod];
    if (newStyleConfig) {
      const newTransform = newStyleConfig.defaultTransform;
      const styleName = newStyleConfig.styleName;
      const styleSettings = newStyleConfig.settings ?? {};
      onChange(newTransform, styleName, styleSettings);
    }
  };

  const handleTransformKwargChange = (name: string, value: any) => {
    const newTransform = {
      ...plotTransform,
      kwargs: {
        ...plotTransform.kwargs,
        [name]: value,
      },
    };
    const newStyleConfig =
      PLOT_STYLE_INFO[plot.config.plot_type][plotTransform.transform];
    const styleSettings = newStyleConfig?.settings ?? {};
    onChange(newTransform, style, styleSettings);
  };

  const currentTransformInputs =
    PLOT_STYLE_INFO[plot.config.plot_type][plotTransform.transform]?.inputs;

  return (
    <Box height="100%" width="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: 0.5, py: 1 }}
      >
        <Typography
          variant="button"
          sx={{
            fontWeight: 800,
            fontSize: 13,
            width: "fit-content",
            color: "#c6c6c6",
          }}
        >
          TRANSFORM SETTINGS
        </Typography>
      </Box>

      <Divider />

      <Box sx={{ p: 2 }}>
        <RAMFormControl fullWidth size="small">
          <Select
            value={plotTransform.transform}
            onChange={(e) =>
              handleTransformChange(e.target.value as TransformMethod)
            }
          >
            {Object.entries(PLOT_STYLE_INFO[plot.config.plot_type]).map(
              ([value, config]) => (
                <MenuItem key={value} value={value}>
                  {config.name}
                </MenuItem>
              ),
            )}
          </Select>
        </RAMFormControl>
      </Box>
      <Divider />
      {plot.config.plot_type === PlotType.LinePlot && (
        <Box sx={{ p: 2 }}>
          <RAMFormControl fullWidth size="small">
            <InputLabel id="transform-select-label">
              Select Periodicity
            </InputLabel>
            <Select
              labelId="periodicity-select"
              value={plot.config.data_config.periodicity}
              onChange={(e) =>
                plot.update({
                  data_config: {
                    ...plot.config.data_config,
                    periodicity: e.target.value as Periodicity,
                  },
                })
              }
              label="Select Periodicity"
            >
              {Object.values(Periodicity).map((period) => (
                <MenuItem key={period} value={period}>
                  {period}
                </MenuItem>
              ))}
            </Select>
          </RAMFormControl>
        </Box>
      )}
      {currentTransformInputs && (
        <Box sx={{ p: 2 }}>
          {Object.entries(currentTransformInputs).map(
            ([key, { label, type }]) => {
              if (type === "portfolio") {
                return (
                  <Box sx={{ py: 2 }}>
                    {label}
                    <PortfolioItem
                      showName={false}
                      portfolio={plotTransform.kwargs[key] as Portfolio}
                      onChange={(portfolio) =>
                        handleTransformKwargChange(key, portfolio)
                      }
                    />
                  </Box>
                );
              }
              return (
                <RAMTextField
                  key={key}
                  fullWidth
                  label={label}
                  type={type}
                  defaultValue={plotTransform.kwargs[key] || ""}
                  onBlur={(e) =>
                    handleTransformKwargChange(
                      key,
                      type === "number"
                        ? Number(e.target.value)
                        : e.target.value,
                    )
                  }
                  size="small"
                />
              );
            },
          )}
        </Box>
      )}
    </Box>
  );
};
export default TransformPanel;
