import React from "react";
import { usePlotContext } from "../../../../../contexts/PlotContext";
import {
  BaseSettings,
  Justify,
} from "../../../../../types/plot_settings/plot_settings_interfaces";
import TextInput from "../../../../../../podcast_post/components/TextInput";
import LabelInput from "../setting_inputs/LabelInput";
import { Divider, Stack, SxProps } from "@mui/material";

const TitleSettings: React.FC = () => {
  const { plot } = usePlotContext();

  const isValidSettings = (settings: any): settings is BaseSettings => {
    const baseSettingsKeys = Object.keys(settings) as Array<keyof BaseSettings>;
    return baseSettingsKeys.every((key) => key in settings);
  };

  const settings: BaseSettings = plot.config.settings;
  if (!isValidSettings(settings)) {
    throw new Error("Settings are invalid");
  }

  return (
    <Stack gap={2}>
      <LabelInput
        value={settings.title || ""}
        fontSize={settings.title_fontsize}
        justify={settings.title_justify}
        label="Title"
        onChange={(value) =>
          plot.update({
            ...plot,
            settings: { ...plot.config.settings, title: value },
          })
        }
        onFontSizeChange={(value) =>
          plot.update({
            ...plot,
            settings: { ...plot.config.settings, title_fontsize: value },
          })
        }
        onJustifyChange={(value) =>
          plot.update({
            ...plot,
            settings: { ...plot.config.settings, title_justify: value },
          })
        }
      />
      <Divider sx={{ mb: 2 }} />
      <LabelInput
        value={settings.subtitle || ""}
        fontSize={settings.subtitle_fontsize}
        justify={settings.subtitle_justify}
        label="Subtitle"
        onChange={(value) =>
          plot.update({
            ...plot,
            settings: { ...plot.config.settings, subtitle: value },
          })
        }
        onFontSizeChange={(value) =>
          plot.update({
            ...plot,
            settings: { ...plot.config.settings, subtitle_fontsize: value },
          })
        }
        onJustifyChange={(value) =>
          plot.update({
            ...plot,
            settings: { ...plot.config.settings, subtitle_justify: value },
          })
        }
      />
      <Divider sx={{ mb: 2 }} />
      <LabelInput
        value={settings.caption || ""}
        fontSize={settings.caption_fontsize}
        justify={settings.caption_justify}
        label="Caption"
        onChange={(value) =>
          plot.update({
            ...plot,
            settings: { ...plot.config.settings, caption: value },
          })
        }
        onFontSizeChange={(value) =>
          plot.update({
            ...plot,
            settings: { ...plot.config.settings, caption_fontsize: value },
          })
        }
        onJustifyChange={(value) =>
          plot.update({
            ...plot,
            settings: { ...plot.config.settings, caption_justify: value },
          })
        }
      />
    </Stack>
  );
};

TitleSettings.displayName = "Titles";
export default TitleSettings;
