import React from "react";
import { usePlotContext } from "../../../../../contexts/PlotContext";
import { ToggleInput } from "../setting_inputs/ToggleInput";
import { Box } from "@mui/material";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import AppsIcon from "@mui/icons-material/Apps";
import { CorrelPlotSettings } from "../../../../../types/plot_settings/plot_settings_interfaces";

const MiscCorrelSettings: React.FC = () => {
  const { plot } = usePlotContext();

  const isValidSettings = (settings: any): settings is CorrelPlotSettings => {
    const baseSettingsKeys = Object.keys(settings) as Array<
      keyof CorrelPlotSettings
    >;
    return baseSettingsKeys.every((key) => key in settings);
  };

  const settings = plot.config.settings as CorrelPlotSettings;
  if (!isValidSettings(settings)) {
    throw new Error("Settings are invalid");
  }

  return (
    <Box display="flex" gap={2}>
      <ToggleInput
        IconComponent={CropSquareIcon}
        label="Square Plot"
        value={settings.square}
        onChange={(value) =>
          plot.update({
            ...plot,
            settings: {
              ...settings,
              square: value,
            },
          })
        }
      />

      <ToggleInput
        IconComponent={AppsIcon}
        label="Mask Upper Triangle"
        value={settings.mask_upper}
        onChange={(value) =>
          plot.update({
            ...plot,
            settings: {
              ...settings,
              mask_upper: value,
            },
          })
        }
      />
    </Box>
  );
};

MiscCorrelSettings.displayName = "Misc";
export default MiscCorrelSettings;
