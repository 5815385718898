import React from "react";
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

import {
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
} from "@mui/icons-material";
import { InputProps } from "./InputProps";
import { Justify } from "../../../../../types/plot_settings/plot_settings_interfaces";

export const JustifyInput: React.FC<InputProps<Justify>> = ({
  label,
  value,
  onChange,
  sx,
}) => (
  <Box sx={sx}>
    {label && <Typography variant="caption">Justification</Typography>}
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(_, newValue) => newValue && onChange(newValue)}
      aria-label="text alignment"
      size="small"
      fullWidth
    >
      <ToggleButton value="left" aria-label="left aligned">
        <FormatAlignLeft />
      </ToggleButton>
      <ToggleButton value="center" aria-label="centered">
        <FormatAlignCenter />
      </ToggleButton>
      <ToggleButton value="right" aria-label="right aligned">
        <FormatAlignRight />
      </ToggleButton>
    </ToggleButtonGroup>
  </Box>
);
