import React from "react";
import { usePlotContext } from "../../../../../contexts/PlotContext";
import {
  HorizontalLineType,
  LinePlotSettings,
} from "../../../../../types/plot_settings/plot_settings_interfaces";
import { NumberInput } from "../setting_inputs/NumberInput";
import { TextInput } from "../setting_inputs/TextInput";
import { SelectInput } from "../setting_inputs/SelectInput";
import { HORIZONTAL_LINE_OPTIONS } from "../../../../../types/plot_settings/plot_settings_info";
import ColorInput from "../setting_inputs/ColorInput";
import { Box, Stack } from "@mui/material";

const HorizontalLineSettings: React.FC = () => {
  const { plot } = usePlotContext();

  const isValidSettings = (settings: any): settings is LinePlotSettings => {
    const baseSettingsKeys = Object.keys(settings) as Array<
      keyof LinePlotSettings
    >;
    return baseSettingsKeys.every((key) => key in settings);
  };

  const settings = plot.config.settings as LinePlotSettings;
  if (!isValidSettings(settings)) {
    throw new Error("Settings are invalid");
  }

  return (
    <Stack gap={2}>
      <NumberInput
        label="X Intercept"
        value={settings.place_horizontal_line_at || null}
        onChange={(value) =>
          plot.update({
            ...plot,
            settings: {
              ...settings,
              place_horizontal_line_at: value || undefined,
            },
          })
        }
      />
      <Box display="flex" gap={2} alignItems="center">
        <NumberInput
          label="Thickness"
          disabled={settings.place_horizontal_line_at === null}
          value={settings.horizontal_line_size || null}
          onChange={(value) =>
            plot.setSettings({
              horizontal_line_size: value || undefined,
            })
          }
        />
        <SelectInput
          label="Type"
          disabled={settings.place_horizontal_line_at === null}
          value={settings.horizontal_line_type}
          onChange={(value) =>
            plot.update({
              ...plot,
              settings: { ...settings, horizontal_line_type: value },
            })
          }
          options={HORIZONTAL_LINE_OPTIONS}
        />
        <ColorInput
          label="Color"
          value={settings.horizontal_line_color}
          onChange={(value) =>
            plot.update({
              ...plot,
              settings: { ...settings, horizontal_line_color: value },
            })
          }
        />
      </Box>
    </Stack>
  );
};
HorizontalLineSettings.displayName = "Horizontal Line";
export default HorizontalLineSettings;
